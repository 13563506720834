import { ref } from 'vue';
import axios from '@/axios';
import store from '@/store'

export default function useAuth() {
    const signInErrors = ref([]); //ref: to make it reactive
    const signUpErrors = ref([]); //ref: to make it reactive
    const signUpMsgs = ref([]);
    const clearFormInputs = ref(false)

    const signIn = async (data, redirectTo = null) => {
        signInErrors.value = [];
        clearFormInputs.value = false;
        try {
            let response = await axios.post('/api/login/', data);
            let responseBody = response.data.data;
            await store.dispatch("setLoggedInData", responseBody)
            if (redirectTo)
                window.location.assign(redirectTo)
            else
                window.location.assign('/dashboard')
        } catch (e) {
            signInErrors.value = await store.dispatch("handleException", e);
        }
    }

    const signUp = async (data) => {
        signUpErrors.value = [];
        clearFormInputs.value = false;
        try {
            let response = await axios.post('/api/register/', data);
            let responseBody = response.data;
            signUpMsgs.value = responseBody.message;
            clearFormInputs.value = true;
        } catch (e) {
            signUpErrors.value = await store.dispatch("handleException", e);
        }

    }

    return {
        signUpMsgs,
        signInErrors,
        signUpErrors,
        signIn,
        signUp,
        clearFormInputs
    }
}

<template>
  <div class="page-content">
    <!-- inner page banner -->
    <div class="dlab-bnr-inr overlay-black-dark banner-content bnr">
      <div class="container">
        <div class="dlab-bnr-inr-entry">
          <h1 class="text-white">Create Parent Account</h1>
          <!-- Breadcrumb row -->
          <div class="breadcrumb-row">
            <ul class="list-inline">
              <li>
                <router-link :to="{ name: 'Home' }">Home</router-link>
              </li>
              <li>Create Parent Account</li>
            </ul>
          </div>
          <!-- Breadcrumb row END -->
        </div>
      </div>
    </div>
    <!-- body area -->
    <div class="section-full content-inner-2 contact-style-3">
      <div class="container">
        <div class="row dzseth">
          <div class="col-lg-6 col-md-6 m-b30">
            <!-- FeedBack -->
            <feedback-messages :errors="signInErrors" />
            <!-- /FeedBack -->
            <div class="p-a30 border-1 seth">
              <form @submit.prevent="userSignIn()">
                <h4 class="font-weight-700">Login</h4>
                <p class="font-weight-600">
                  If you have an account with us, please log in.
                </p>
                <div class="form-group">
                  <label class="font-weight-700"
                    >E-mail <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    v-model="signInForm.email"
                    class="form-control"
                    required
                    placeholder="Your Email Id"
                  />
                </div>
                <div class="form-group">
                  <label class="font-weight-700"
                    >Password <span style="color: red">*</span></label
                  >
                  <input
                    type="password"
                    v-model="signInForm.password"
                    class="form-control"
                    required
                    placeholder="Type Password"
                  />
                </div>
                <div class="text-left">
                  <button
                    :disabled="disableSignInBtn"
                    name="submit"
                    type="submit"
                    class="site-button"
                    value="Submit"
                    onclick="this.blur();"
                  >
                    login
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 m-b30">
            <!-- FeedBack -->
            <feedback-messages :msgs="signUpMsgs" :errors="signUpErrors" />
            <!-- /FeedBack -->
            <div class="p-a30 border-1 seth">
              <div class="tab-content">
                <form
                  @submit.prevent="userSignUp()"
                  class="tab-pane active col-12 p-a0"
                >
                  <h4 class="font-weight-700">First Time?</h4>
                  <p class="font-weight-600">
                    Please fill all the following data.
                  </p>
                  <div class="form-row">
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >First Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        v-model="signUpForm.firstname"
                        required=""
                        class="form-control"
                        placeholder="First name"
                      />
                    </div>
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >Last Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        v-model="signUpForm.lastname"
                        required=""
                        class="form-control"
                        placeholder="Last name"
                      />
                      <input type="hidden" v-model="signUpForm.label" />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >E-mail <span style="color: red">*</span></label
                      >
                      <input
                        type="email"
                        v-model="signUpForm.email"
                        required=""
                        class="form-control"
                        placeholder="Your Email Id"
                      />
                    </div>
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >E-mail Confirmation
                        <span style="color: red">*</span></label
                      >
                      <input
                        type="email"
                        v-model="signUpForm.email_confirmation"
                        required=""
                        class="form-control"
                        placeholder="Confirm your Email"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >Password <span style="color: red">*</span></label
                      >
                      <input
                        v-model="signUpForm.password"
                        required=""
                        class="form-control"
                        placeholder="Type Password"
                        type="password"
                      />
                    </div>
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >Password Confirmation
                        <span style="color: red">*</span></label
                      >
                      <input
                        v-model="signUpForm.password_confirmation"
                        required=""
                        class="form-control"
                        placeholder="Confirm your Password"
                        type="password"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label class="font-weight-700"
                        >Phone <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        v-model="signUpForm.phone"
                        required=""
                        class="form-control"
                        placeholder="Your Phone Number"
                      />
                    </div>
                  </div>
                  <div class="text-left">
                    <button
                      :disabled="disableSignUpBtn"
                      name="submit"
                      type="submit"
                      class="site-button"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-1" id="modal_body">
            <div class="alert alert-danger mb-0 text-center" role="alert">
              <h3>Case Failed!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useAuth from "@/composables/auth";
import { reactive, ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    FeedbackMessages,
  },

  setup() {
    const disableSignInBtn = ref(false);
    const disableSignUpBtn = ref(false);
    const route = useRoute();
    const redirectTo = ref(null);

    //Used from the auth
    const {
      signUpMsgs,
      signInErrors,
      signUpErrors,
      signIn,
      signUp,
      clearFormInputs,
    } = useAuth();

    //Sign in form
    const signInForm = reactive({
      email: "",
      password: "",
    });

    onBeforeMount(async () => {
      if (typeof route.query.redirect !== "undefined") {
        redirectTo.value = route.query.redirect;
      }
    });

    const userSignIn = async () => {
      signInErrors.value = [];
      disableSignInBtn.value = true;
      await signIn(
        {
          ...signInForm,
        },
        redirectTo.value
      ).then(() => {
        disableSignInBtn.value = false;
        if (clearFormInputs.value == true) resetSignInForm();
      });
    };

    //Sign up from
    const signUpForm = reactive({
      firstname: "",
      lastname: "",
      phone: "",
      label: "Parent",
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
    });

    const userSignUp = async () => {
      signUpErrors.value = [];
      signUpMsgs.value = [];
      disableSignUpBtn.value = true;
      await signUp({
        ...signUpForm,
      }).then(() => {
        window.scrollTo(0, 0);
        disableSignUpBtn.value = false;
        if (clearFormInputs.value == true) resetSignUpForm();
      });
    };

    //reset the sign in form inputs
    const resetSignInForm = () => {
      signInForm.email = "";
      signInForm.password = "";
    };

    //reset the sign up form inputs
    const resetSignUpForm = () => {
      signUpForm.firstname = "";
      signUpForm.lastname = "";
      signUpForm.phone = "";
      signUpForm.label = "Parent";
      signUpForm.email = "";
      signUpForm.email_confirmation = "";
      signUpForm.password = "";
      signUpForm.password_confirmation = "";
    };

    return {
      signUpMsgs,
      signInForm,
      userSignIn,
      signUpForm,
      userSignUp,
      signInErrors,
      signUpErrors,
      disableSignInBtn,
      disableSignUpBtn,
    };
  },
};
</script>

<style scoped>
.bnr {
  background-image: url("~@/assets/imgs/bnr1.jpg") !important;
}
</style>
